<template>
  <div class="flex w-full flex-col">
    <router-link to="/">
      <div class="bg-innerPage-header flex h-32 w-full flex-wrap content-center lg:h-48"></div>
    </router-link>
    <div class="bg-inner-page">
      <div class="mx-auto px-4 py-10 lg:w-2/3 lg:py-24">
        <router-link to="/" class="mb-5 inline-block rounded-full bg-yellow-400 px-5 py-3 font-bold">
          « Voltar
        </router-link>

        <h1 class="mb-8 uppercase leading-tight">
          <span class="text-7xl">moto</span>
          <br />
          <span class="text-7xl">ciclista</span>
          <br />
          <span class="text-7xl font-bold">legal</span>
        </h1>
        <h3 class="mb-4 text-2xl font-bold tracking-wide">Pré-requisitos</h3>
        <div class="text-xl">
          <p class="mb-5">
            Para se candidatar aos benefícios do programa é necessário ser membro de família comprovadamente de baixa
            renda, ou seja, aquelas cuja soma do rendimento mensal de todos os membros é de até dois salários mínimos.
          </p>

          <p class="mb-5">
            A renda familiar mensal é a soma dos rendimentos brutos de todos os membros da família, e são excluídos
            desse cálculo rendimentos provenientes de:
          </p>

          <ul class="mb-5 ml-6 list-disc leading-8">
            <li>Programa Bolsa Família</li>
            <li>Programa Nacional de Inclusão do Jovem (Pró-Jovem)</li>
            <li>Auxílio Emergencial Financeiro</li>
            <li>
              Programas de transferência de renda destinados à população atingida por desastres, estado de calamidade
              pública ou situação de emergência
            </li>
            <li>Programas de transferência condicionada de renda, do Estado do Amazonas ou pelo município</li>
          </ul>

          <p class="mb-5">Além disso, a isenção do pagamento só poderá ser aplicada para:</p>

          <ul class="mb-5 ml-6 list-disc leading-8">
            <li>Pessoas que ganhem até dois salários mínimos</li>
            <li>Habilitados para conduzir motocicleta, há pelo menos dois anos</li>
            <li>No mínimo 21 anos</li>
            <li>Comprovadamente domiciliados no estado do Amazonas</li>
          </ul>

          <p class="mb-5">
            A entrega de capacete e colete será feita apenas para o motociclista que tenha o curso de mototaxista ou
            motofretista válido.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
