
import { computed, defineComponent } from 'vue';
import util from '../util';
import Menu from '../components/Menu.vue';

export default defineComponent({
  setup() {
    const municipios = computed(() => {
      return util
        .get_municipios_setima_fase()
        .filter((municipio) => municipio.nome.toLowerCase() !== 'manaus')
        .sort((municipioA, municipioB) => ('' + municipioA.nome).localeCompare(municipioB.nome));
    });

    const trackClick = util.trackClick;

    return { municipios, trackClick };
  },
  components: {
    Menu,
  },
});
